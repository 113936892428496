export function getStageSpecificValue(stage: string, values: { [key: string]: string }) {
  const entries = Object.entries(values).map(([key, value]) => {
    value = value.replace("#STAGE#", stage);

    return [key, value];
  });

  for (const [key, value] of entries) {
    //To be safe, only treat a stage as regex if it begins with ^ and ends with $
    const keyIsRegexp = key.startsWith("^") && key.endsWith("$");
    if (keyIsRegexp && new RegExp(key).test(stage)) {
      return value;
    } else if (stage === key) {
      return value;
    }
  }
  return values.default;
}
