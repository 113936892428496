<div class="sleigh-santa">
  <div class="santa santa--sleigh">
    <div class="santa__hat">
      <div class="santa__hat-part"></div>
      <div class="santa__hat-part"></div>
    </div>
    <div class="santa__face">
      <div class="santa__eyebrows santa__eyebrows--right"></div>
      <div class="santa__eyebrows santa__eyebrows--left"></div>
      <div class="santa__eye santa__eye--right"></div>
      <div class="santa__eye santa__eye--left"></div>
      <div class="santa__nose"></div>
      <div class="santa__cheek santa__cheek--right"></div>
      <div class="santa__cheek santa__cheek--left"></div>
      <div class="santa__beard">
        <div class="santa__beard-part"></div>
        <div class="santa__beard-part"></div>
        <div class="santa__beard-part"></div>
      </div>
      <div class="santa__mouth"></div>
    </div>
    <div class="santa__body">
      <div class="santa__body-top"></div>
      <div class="santa__hand santa__hand--left">
        <div class="santa__hand-inner"></div>
      </div>
      <div class="santa__hand santa__hand--right"></div>
    </div>
  </div>
  <div class="sleigh-feet"></div>
  <div class="lead">
    <div class="lead-inner"></div>
  </div>
  <div class="lead lead--back">
    <div class="lead-inner"></div>
  </div>
  <div class="reindeer">
    <div class="reindeer__face">
      <div class="reindeer__ear"></div>
      <div class="reindeer__horn reindeer__horn--right"></div>
      <div class="reindeer__horn reindeer__horn--left"></div>
    </div>
    <div class="reindeer__body">
      <div class="reindeer__foot reindeer__foot--front">
        <div class="reindeer__foot-inner"></div>
      </div>
      <div class="reindeer__foot reindeer__foot--front reindeer__foot--inside">
        <div class="reindeer__foot-inner"></div>
      </div>
      <div class="reindeer__foot reindeer__foot--back">
        <div class="reindeer__foot-inner"></div>
      </div>
      <div class="reindeer__foot reindeer__foot--back reindeer__foot--inside">
        <div class="reindeer__foot-inner"></div>
      </div>
      <div class="reindeer__tail"></div>
      <div class="reindeer__spots"></div>
    </div>
  </div>
  <div class="particles"></div>
</div>
