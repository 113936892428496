<dentr-modal>
  <div class="mb-6 flex flex-row items-center justify-between">
    <h2 class="heading-xl">Elevate User</h2>
    <button class="button-tertiary" (click)="close()">Close</button>
  </div>

  <form [formGroup]="createPassForm" (ngSubmit)="submit()" class="flex flex-col">
    <label for="passwordEl" class="mb-2">Enter the password</label>
    <input #passwordEl id="passwordEl" type="password" formControlName="password" />
  </form> </dentr-modal
>>
