<div *ngIf="is_ready && jwtService.getJWT()">
  <div class="absolute top-1/2 z-50 -mt-[110px]" *ngIf="showSantaSleigh">
    <div @leftToRightAnimation (@leftToRightAnimation.start)="onLeftToRightAnimateStart()" (@leftToRightAnimation.done)="onLeftToRightAnimateDone()">
      <dentr-santa-sleigh></dentr-santa-sleigh>
    </div>
  </div>
  <div
    @fadeInAnimation
    *ngIf="isOverlayOpen"
    (click)="closeOverlay()"
    class="fixed inset-0 z-40 bg-gray-500 bg-opacity-75 transition-opacity"
    [attr.aria-hidden]="isOverlayOpen"
  ></div>
  <dentr-notification></dentr-notification>
  <dentr-main></dentr-main>
  <dentr-impersonation *ngIf="devService.isUserImpersonating"></dentr-impersonation>
</div>
