import { generateNanoId } from "@shared/utils";
import { forOwn } from "lodash";

export class DefaultDomainBase {
  public domain: string;
  public id: string;

  public constructor(baseItem?: any) {
    if (!baseItem || !baseItem.id) {
      this.id = generateNanoId();
      if (!baseItem) return;
    }

    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }
}
