import { Injectable } from '@angular/core';
import { E_ManageFeatures } from '@backend/common/enums/feature-flags.enum';
import { FeatureFlagBase } from '@backend/graph/feature_flags/feature-flag-base';

const CACHE_KEY = 'feature-flags';

/**
 * This is copy/pasted and slightly modified from app-patient. I have created an issue to allow us to share this between apps at some point in the future..
 * https://github.com/dentally/dentr/issues/1499
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  /**
   * Stores feature flags
   *
   * @param featureFlags Feature flags
   */
  public setFeatureFlags(featureFlagBases: Array<FeatureFlagBase>): void {
    const featureFlags = featureFlagBases.reduce((allFeatureFlags: { [x: string]: any }, featureFlag: { name: string; value: string }) => {
      const { name, value } = featureFlag;
      if (value === 'true') {
        allFeatureFlags[name] = true;
      } else if (value === 'false') {
        allFeatureFlags[name] = false;
      } else {
        allFeatureFlags[name] = value;
      }
      return allFeatureFlags;
    }, {});
    window.localStorage.setItem(CACHE_KEY, JSON.stringify(featureFlags));
  }

  public getFeatureFlagValue<T>(feature: E_ManageFeatures, defaultValue: T): T {
    try {
      return this._getFeatureFlag<T>(feature);
    } catch {
      return defaultValue;
    }
  }

  /**
   * Gets multisite email address feature flag value
   */
  public get multisiteEmailAddress(): boolean {
    try {
      return this._getFeatureFlag<boolean>(E_ManageFeatures.MULTISITE_EMAIL_ADDRESS);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  public get customFavicon(): boolean {
    try {
      return this._getFeatureFlag<boolean>(E_ManageFeatures.CUSTOM_FAVICON);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  public get L2_L1_Login() {
    try {
      return this._getFeatureFlag<boolean>(E_ManageFeatures.L2_L1_Login);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  public get logoutOnInactivity() {
    try {
      return this._getFeatureFlag<boolean>(E_ManageFeatures.LOGOUT_ON_INACTIVITY);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  public get blockMultipleLogins() {
    try {
      return this._getFeatureFlag<boolean>(E_ManageFeatures.BLOCK_MULTIPLE_LOGINS);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  /**
   * Gets the value of a feature flag
   *
   * @param featureFlag Feature for which to get the value
   */
  private _getFeatureFlag<T>(featureFlag: string): T {
    const json = window.localStorage.getItem(CACHE_KEY);

    if (!json) throw 'No feature flags found';

    const featureFlags = JSON.parse(json);

    if (featureFlags[featureFlag] === undefined) throw `Feature flag not set (${featureFlag})`;

    return featureFlags[featureFlag] as T;
  }
}
