import { Routes } from '@angular/router';
import { FeatureGuard } from './shared/services/guards/feature-guard';

export const APP_ROUTES: Routes = [
  {
    path: 'online-booking',
    loadChildren: () => import('./main/pages/online-booking/online-booking.routing').then((m) => m.ONLINE_BOOKING_ROUTES),
    canActivate: [FeatureGuard],
  },
  {
    path: 'online-signing',
    loadChildren: () => import('./main/pages/online-signing/online-signing.routing').then((m) => m.ONLINE_SIGNING_ROUTES),
    canActivate: [FeatureGuard],
  },
  {
    path: 'payments',
    loadChildren: () => import('./main/pages/payments/payments.routing').then((m) => m.PAYMENTS_ROUTES),
    canActivate: [FeatureGuard],
  },
  {
    path: 'patient-notifications',
    loadChildren: () => import('./main/pages/patient-notifications/patient-notifications.routing').then((m) => m.PATIENT_NOTIFICATION_ROUTES),
    canActivate: [FeatureGuard],
  },
  {
    path: 'patient-details',
    loadChildren: () => import('./main/pages/patient-details/patient-details.routing').then((m) => m.PATIENT_DETAILS_ROUTES),
    canActivate: [FeatureGuard],
  },
  {
    path: 'customise',
    loadChildren: () => import('./main/pages/customise/customise.routing').then((m) => m.CUSTOMISE_ROUTES),
    canActivate: [FeatureGuard],
  },
  {
    path: 'support-tools',
    loadChildren: () => import('./main/pages/support-tools/support-tools.routing').then((m) => m.SUPPORT_TOOLS_ROUTES),
    canActivate: [FeatureGuard],
  },
  {
    path: 'dev-mode',
    loadChildren: () => import('./main/pages/dev-mode/dev-mode.routing').then((m) => m.DEV_ROUTES),
    canActivate: [FeatureGuard],
  },
  {
    path: 'devices',
    loadChildren: () => import('./main/pages/devices/devices.routing').then((m) => m.DEVICES_ROUTES),
    canActivate: [FeatureGuard],
  },
  {
    path: '**',
    redirectTo: 'online-booking',
  },
];
